import styled from '@emotion/styled'
import { css } from '@emotion/core'

const ChatBubbleContainer = styled.div`
  position: relative;
  background-color: ${props => props.primary ? props.theme.colors.primary : "white"};
  border-radius: 10px;
  padding: 14px;
  box-shadow: ${props => props.right ? "-2px 2px" : "2px 2px"} 4px rgba(0,0,0,0.2);
  width: fit-content;
  
  :after {
    content: '';
    position: absolute;
    top: 7px;
    width: 0;
    height: 0;
    border: 14px solid transparent;
    border-top: 0;
    margin-top: -7px;
    ${props => props.right ? css`
      right: 7px;
      border-left-color: ${props.primary ? props.theme.colors.primary : "white"};
      border-right: 0;
      margin-right: -14px;
    ` : props.left ? css`
      left: 7px;
      border-right-color: ${props.primary ? props.theme.colors.primary : "white"};
      border-left: 0;
      margin-left: -14px;
    ` : css``}
  }
`

export {
  ChatBubbleContainer 
}

/*

    */