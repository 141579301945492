import React, { useState } from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { MokuContainer, MokuAnimation } from './Moku.style'
import ChatWindow from '../ChatWindow'
import MokuWelcome from '../MokuWelcome'
import ExampleChat from '../ExampleChat'

function Moku() {
  const [open, setOpen] = useState(false)
  const [animating, setAnimating] = useState(true)
  const [startedConversation, setStartedConversation] = useState(false)

  const data = useStaticQuery(graphql`{
    moku: file(relativePath: { eq: "Moku.png" }) {
      childImageSharp {
        fluid(maxWidth: 272) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }`)

  return <>
    <MokuContainer open={open}>
      {open ?
      <ChatWindow open={open} header={startedConversation} setOpen={(newOpen) => {
        if (!newOpen) {
          setAnimating(true)
          setTimeout(() => setAnimating(false), 500)
          setStartedConversation(false)
        }
        setOpen(newOpen)
      }}>
        {startedConversation ?
          <ExampleChat />
        : 
          <MokuWelcome startConversation={() => setStartedConversation(true) } />
        }
      </ChatWindow> : 
      <MokuAnimation animating={animating} onClick={() => setOpen(true)}>
        <Img fluid={data.moku.childImageSharp.fluid} alt="Moku" />
      </MokuAnimation>
      }
    </MokuContainer>
  </>
}

export default Moku
