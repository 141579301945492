import styled from '@emotion/styled'
import { ChatBubbleContainer } from '../RequestContainer/RequestContainer.style'
import { Text } from '../../../generic'

const RightChatBubbleContainer = styled(Text)`
  padding-top: 14px;
  padding-left: 14px;
  padding-right: 14px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
`

RightChatBubbleContainer.defaultProps = {
  white: true
}

export {
  ChatBubbleContainer,
  RightChatBubbleContainer
}