import styled from '@emotion/styled'
import theme from '../../theme.style'

const ContentContainer = styled.div`
  margin: 0 auto;
  min-height: 100vh;
`

const getCookieBarStyle = theme => ({
  background: theme.colors.dark
})

const getAcceptCookieButtonStyle = (theme) => ({
  background: theme.colors.primary,
  color: "white",
  borderRadius: "20px",
  padding: "7px",
  paddingLeft: "28px",
  paddingRight: "28px",
  fontWeight: 900
})

export {
  ContentContainer,
  getCookieBarStyle,
  getAcceptCookieButtonStyle
}
