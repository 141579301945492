import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { device } from '../../theme.style'

const MokuWelcomeHeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const MokuContainer = styled.div`
  width: 146px;
  position: absolute;
  right: 91px;
  top: 0px;
`

const MokuWelcomeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
`

const MokuWelcomeHeaderText = styled.div`
  position: absolute;
  left: 45px;
  top: 42px;
`


const MokuWelcomeFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  margin-bottom: 7px;
`

const MokuWelcomeContentsContainer = styled.div`
  margin-left: 28px;
  margin-right: 28px;
  padding-top: 28px;
  padding-bottom: 28px;
`

export {
  MokuWelcomeContainer,
  MokuWelcomeHeaderContainer,
  MokuContainer,
  MokuWelcomeFieldContainer,
  MokuWelcomeHeaderText,
  MokuWelcomeContentsContainer
}