import styled from '@emotion/styled'
import { Row, Col } from '../generic/grid'
 
const TermsConditionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: auto;
  text-align: center;
  padding: 14px;
`

const LogoImageContainer = styled.div`
  margin-top: 66px;
  margin-bottom: 52px;
  width: 118px;

  img {
    margin: 0;
  }
`

const TermsRow = styled.div`
  display: flex;
  flex-direction: row;
`

const TermsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding-top: 15px;
  padding-right: 14px;
  padding-bottom: 21px;
`

const RedText = styled.span`
  color: ${props => props.theme.colors.red};
  font-size: 20px;
  position: relative; 
  top: 3px;
  font-weight: 900;
`

const TermsConditionsContentContainer = styled(Col)`
  margin-top: 7.8%;
  padding-top: 14px;
`

const ButtonsContainer = styled(Row)`
  margin-top: 7.8%;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

const MandatoryTextContainer = styled(Row)`
  margin-top: 7.8%;
  padding-left: 40px;
`

export {
  TermsConditionsContainer,
  LogoImageContainer,
  TermsRow,
  TermsTextContainer,
  RedText,
  TermsConditionsContentContainer,
  ButtonsContainer,
  MandatoryTextContainer
}