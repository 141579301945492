import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { FooterContainer, DetailsContainer, DetailItemContainer, DetailItemHeader, LogoContainer, LegalLinksContainer, HorizontalRule, AddressText, CopyrightText, SocialIconsContainer, LegalLink, SocialIcon, SocialImage } from './Footer.style'
import { Col } from '../generic/grid'
import { Text } from '../generic'
import { useTranslation } from '../../hooks'
import { social } from '../../images'

function Footer() {
  const data = useStaticQuery(graphql`{
    meerkatLogo: file(relativePath: { eq: "MeerkatWhite.png" }) {
      childImageSharp {
        fluid(maxHeight: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facebook: file(relativePath: { eq: "facebookWhite.png" }) {
      childImageSharp {
        fluid(maxHeight: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    linkedIn: file(relativePath: { eq: "linkedInWhite.png" }) {
      childImageSharp {
        fluid(maxHeight: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    instagram: file(relativePath: { eq: "instagramWhite.png" }) {
      childImageSharp {
        fluid(maxHeight: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    twitter: file(relativePath: { eq: "twitterWhite.png" }) {
      childImageSharp {
        fluid(maxHeight: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }`)

  const { t } = useTranslation("Footer")

  return <FooterContainer>
    <DetailsContainer>
      <Col>
        <LogoContainer>
          <Img fluid={data.meerkatLogo.childImageSharp.fluid} alt="Meerkat" />
        </LogoContainer>
      </Col>
      <Col>
        <DetailItemContainer>
          <DetailItemHeader><h2>{t("location")}</h2></DetailItemHeader>
          <AddressText>{t("address")}</AddressText>
        </DetailItemContainer>
      </Col>
      <Col>
        <DetailItemContainer>
          <DetailItemHeader><h2>Connect with us on</h2></DetailItemHeader>
          <SocialIconsContainer>
            <SocialIcon href="https://www.facebook.com/MokuMeerkat/"><Img fluid={data.facebook.childImageSharp.fluid} alt="Facebook" /></SocialIcon>
            <SocialIcon href="https://www.linkedin.com/company/15091168/"><Img fluid={data.linkedIn.childImageSharp.fluid} alt="LinkedIn" /></SocialIcon>
            <SocialIcon href="https://www.instagram.com/mokumeerkat/"><Img fluid={data.instagram.childImageSharp.fluid} alt="Instagram" /></SocialIcon>
            <SocialIcon href="https://twitter.com/MokuMeerkat"><Img fluid={data.twitter.childImageSharp.fluid} alt="Twitter" /></SocialIcon>
            <SocialIcon href="https://www.youtube.com/channel/UCmI-sqG2nlwR4oWYUs-FmnQ"><SocialImage src={social.youTube} alt="YouTube" /></SocialIcon>
            <SocialIcon href="mailto:info@meerkat.co.za"><SocialImage src={social.email} alt="Email" /></SocialIcon>
          </SocialIconsContainer>
        </DetailItemContainer>
      </Col>
    </DetailsContainer>  
    <HorizontalRule />
    <DetailsContainer>
      <LegalLinksContainer>
        <LegalLink href="https://blog.meerkat.co.za/meerkat/terms-of-use/"><Text white bold>{t("terms")}</Text></LegalLink>
        <LegalLink href="https://blog.meerkat.co.za/meerkat/privacy-policy/"><Text white bold>{t("privacy")}</Text></LegalLink>
        <LegalLink href="https://blog.meerkat.co.za/meerkat/resources/"><Text white bold>{t("resources")}</Text></LegalLink>
      </LegalLinksContainer>
    </DetailsContainer>
    <CopyrightText>
      <Text xsmall bold white>{t('copyright')}</Text>
    </CopyrightText>
  </FooterContainer>
}

/*
Logo
Location
ConnectWithUs
LegalLinks
CopyrightInfo
*/

export default Footer