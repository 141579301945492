import styled from '@emotion/styled'
import { device } from '../../theme.style'
import { Link } from 'gatsby'
import { Text } from '../generic'

const HeaderContainer = styled.header`
  background: white;
  position: relative;
  height: 100px;

  ${device.laptopL`
    height: 75px;
    width: 100%;
  `}  

  ${device.laptop`
    height: 50px;
    width: 100%;
  `} 

  ${device.tablet`
    height: 50px;
    width: 100%;
  `}  
`

const TopMenuContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 200px;
  margin-top: 7px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  ${device.laptopL`
    right: 170px;
  `}  

  @media(max-width: 1150px) {
    right: 64px;
  }

  ${device.laptop`
    right: 48px;
  `}

  ${device.tablet`
    display: none;
  `}
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  ${device.tablet`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    height: 50px;
  `}
`

const OpenSideBarContainer = styled.div`
  display: none;

  ${device.tablet`
    display: block;
    position: absolute;
    left: 28px;
    top: 20px;
  `}
`

const Logo = styled.img`
  height: 50px;
  width: 200px;

  ${device.laptopL`
    height: 30px;
    width: 120px;
  `}

  ${device.laptop`
    height: 22px;
    width: 79px;
  `}

  ${device.tablet`
    width: 79px;
    height: 22px;
  `}
`

const LogoLink = styled(Link)`
  position: absolute;
  left: 32px;
  top: 25px;
  height: 50px;
  width: 200px;
  z-index: 2;

  ${device.laptopL`
    height: 25px;
    width: 100px;
    top: 22.5px;
    left: 24px;
  `}

  ${device.laptop`
    width: 79px;
    height: 22px;
    top: 14px;
    left: 16px;
  `}

  ${device.tablet`
    width: 79px;
    left: auto;
    right: auto;
    top: 14px;
    height: 22px;
  `}
`

const ProfileText = styled(Text)`
  padding-left: 14px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media(max-width: 1150px) {
    display: none;
  }
`
ProfileText.defaultProps = { bold: true }

const ProfileContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0px;
  right: 32px;
  height: 100%;
  justify-content: center;

  ${device.laptopL`
    right: 24px;
  `}

  ${device.laptop`
    right: 16px;
  `}

  ${device.tablet`
    right: 16px;
  `}
`

const ProfileImage = styled.img`
  height: 54px;
  width: 54px;
  border-radius: 27px;
  margin-bottom: 0;

  ${device.laptopL`
    height: 40px;
    width: 40px;
    border-radius: 20px;
  `}

  ${device.laptop`
    height: 32px;
    width: 32px;
    border-radius: 16px;
  `}

  ${device.tablet`
    width: 22px;
    height: 22px;
    border-radius: 11px;
  `}
`

const LanguageSwitcherContainer = styled.div`
  position: absolute;
  top: 3.5px;
  right: 3.5px;

  ${device.tablet`
   display: none;
  `}
`

const TopMenu = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0
`

const TopMenuItem = styled.li`
  float: left;
  padding-right: 32px;

  ${device.laptopL`
    padding-right: 24px;
  `}

  ${device.laptop`
    padding-right: 16px;
  `}
`

export {
  HeaderContainer,
  TopMenuContainer,
  LogoContainer,
  Logo,
  LogoLink,
  OpenSideBarContainer,
  ProfileText,
  ProfileContainer,
  ProfileImage,
  LanguageSwitcherContainer,
  TopMenu,
  TopMenuItem
}
