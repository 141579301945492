import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { TermsConditionsContainer, LogoImageContainer, TermsRow, TermsTextContainer, RedText, TermsConditionsContentContainer, ButtonsContainer, MandatoryTextContainer } from './TermsConditions.style'
import { Text, Button } from '../generic'
import { renderInput } from '../generic/form'
import { useTranslation } from '../../hooks'
import { GraphQLFormProvider, FieldValue, SubmitButton } from 'react-form-helper'
import gql from 'graphql-tag'
import firebase from 'gatsby-plugin-firebase'

const ACCEPT_TERMS = gql`
  mutation AcceptTerms($input: UpdateClientInput) {
    updateMyProfile(input: $input) {
      id
      acceptedTerms
      marketingConsent
    }
  }
`

function hasMandatoryAcceptance({ acceptedTerms, allowAnalytics }) {
  const result = {}
  if (!acceptedTerms) {
    result.acceptedTerms = "Must accept terms and conditions"
  }
  if (!allowAnalytics) {
    result.allowAnalytics = "Must accept use of data to perform services"
  }
  return result
}

function TermsConditions({ prevRoute, nextRoute }) {
  const data = useStaticQuery(graphql`{
    meerkatLogo: file(relativePath: { eq: "Meerkat-Logo329.png" }) {
      childImageSharp {
        fixed(height: 50) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }`)

  const { t } = useTranslation("TermsConditions")

  const fields = {
    acceptedTerms: {
      type: "checkbox"
    },
    allowAnalytics: {
      type: "checkbox",
      strip: true
    },
    marketingConsent: {
      type: "checkbox"
    }
  }

  return <GraphQLFormProvider 
    name="terms"
    mode="add"
    fields={fields}
    addMutation={ACCEPT_TERMS}
    initialValues={{
      acceptedTerms: true,
      allowAnalytics: true,
      marketingConsent: true
    }}
    validate={hasMandatoryAcceptance}
    renderInput={renderInput}
    afterSubmit={() => {
      firebase
        .analytics()
        .logEvent("TERMS_CONDITIONS_ACCEPT", {})
      navigate(nextRoute)
    }}
  >
    <TermsConditionsContainer>
      <LogoImageContainer>
        <Img fixed={data.meerkatLogo.childImageSharp.fixed} alt="Meerkat" />
      </LogoImageContainer>
      <Text medium bold>{t("description")}</Text>
      <TermsConditionsContentContainer>
        <TermsRow><FieldValue name="acceptedTerms" /><TermsTextContainer><Text small>{t("terms")}<RedText>*</RedText></Text></TermsTextContainer></TermsRow>
        <TermsRow><FieldValue name="allowAnalytics" /><TermsTextContainer><Text small>{t("analytics")}<RedText>*</RedText></Text></TermsTextContainer></TermsRow>
        <TermsRow><FieldValue name="marketingConsent" /><TermsTextContainer><Text small>{t("marketing")}</Text></TermsTextContainer></TermsRow>
        <MandatoryTextContainer>
          <Text red><RedText>*</RedText> mandatory</Text>
        </MandatoryTextContainer>
        <ButtonsContainer>
          <Button inverted title={t("back")} onClick={() => navigate(prevRoute)} />
          <SubmitButton renderSubmitButton={({ submitting, invalid, submit }) => <Button title={t("next")} submitting={submitting} disabled={invalid} onClick={submit} />} />
        </ButtonsContainer>
      </TermsConditionsContentContainer>
    </TermsConditionsContainer>
  </GraphQLFormProvider>
}

export default TermsConditions