import styled from '@emotion/styled'
import { openWindow } from '../ChatWindow/ChatWindow.style'
import { device } from '../../theme.style'

const MokuContainer = styled.div`
  position: fixed;
  max-width: ${props => props.open ? "652px" : "272px"};
  width: ${props => props.open ? "100%" : "33%"};
  bottom: 2%;
  right: 4.3%;
  z-index: 2;

  ${props => props.open && device.tablet`
    bottom: 0;
    max-width: none;
    height: 100vh;
    right: 0;
  `}
`

const MokuAnimation = styled.div`
  animation-name: ${props => props.animating && openWindow};
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-delay: 0s;
`

export {
  MokuAnimation,
  MokuContainer
}