import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { device } from '../../theme.style'

const ChatWindowTitleBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  background-color: ${props => props.theme.colors.primary};
  display: flex;
  flex-direction: row;
  align-items: center;
`

const openWindow = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1.0);
    opacity: 1;
  }
`

const closeWindow = keyframes`
  0% {
    transform: scale(1.0);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
`

const ChatWindowAnimation = styled.div`
  animation-name: ${props => props.closeAnimation ? closeWindow : openWindow};
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
`


const ChatWindowContainer = styled.div`
  position: relative;
  max-height: 882px;
  height: calc(100vh - 28px);
  width: 100%;
  background-color: ${props => props.theme.colors.grey};
  box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
  border-radius: 3px;

  ${device.tablet`
    border-radius: 0px;
    box-shadow: none;
    max-height: none;
    height: 100vh;
  `}
`

const ChatWindowClose = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 3;
`

const ChatWindowContentsContainer = styled.div`
  position: absolute;
  top: ${props => props.header ? "64px" : "0px"};
  left: 0;
  overflow: scroll;
  height: 786px;
  width: 100%;
`

const ChatWindowIconContainer = styled.div`
  margin-left: 21px;
  margin-right: 21px;
`

export {
  ChatWindowContainer,
  ChatWindowTitleBar,
  ChatWindowClose,
  ChatWindowContentsContainer,
  ChatWindowIconContainer,
  ChatWindowAnimation,
  openWindow
}