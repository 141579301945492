import styled from '@emotion/styled'

const SideMenuContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: ${props => props.theme.colors.dark};
  z-index: 1001;
  padding-top: 90px;
  padding-left: 39px;
`

const MenuItemContainer = styled.div`
  margin-bottom: 21px;
`

const CloseMenuContainer = styled.div`
  position: fixed;
  left: 29px;
  top: 18px;
  z-index: 1002;
  
`

export { SideMenuContainer, MenuItemContainer, CloseMenuContainer }
