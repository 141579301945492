import styled from '@emotion/styled'

const ChatBubbleContainer = styled.div`
  padding-top: 7px;
  padding-left: 14px;
  padding-right: 14px
`

export {
  ChatBubbleContainer
}