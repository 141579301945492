import React from 'react'
import ChatBubble from '../../ChatBubble'
import { ChatBubbleContainer } from './RequestContainer.style'
import { Text } from '../../../generic'

function RequestContainer({ text }) {
  return <ChatBubbleContainer>
    <ChatBubble left><Text>{text}</Text></ChatBubble>
  </ChatBubbleContainer>
}

export default RequestContainer