import { useStaticQuery, Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { isLoggedIn } from "../../services/auth"
import LanguageSwitcher from "../LanguageSwitcher"
import { HeaderContainer, TopMenuContainer, LogoContainer, Logo, LogoLink, OpenSideBarContainer, ProfileText, ProfileContainer, ProfileImage, LanguageSwitcherContainer, TopMenu, TopMenuItem } from './Header.style'
import { openMenu, logo, profile } from '../../images'
import { useTranslation } from '../../hooks'
import { useQuery } from "react-apollo"
import { Text } from '../generic'
import { Row, Col } from '../generic/grid'
import gql from 'graphql-tag'
import SideMenu from '../SideMenu'

// The name is used to look up in the i18n file under menu
const menuOptions = [{
  name: "savings",
  bold: true,
  to: "/savings",
  testId: "headerSavingsLink"
}, {
  name: "debt",
  to: "/debt",
  bold: true
}, {
  name: "creditLife",
  bold: true,
  to: '/credit-life'
}, {
  name: "funeral",
  bold: true,
  to: '/funeral'
}, {
  name: "about",
  to: '/about-us'
}, {
  name: "blog",
  href: 'https://blog.meerkat.co.za/the-meerkat-blog/'
}, {
  name: "contact",
  to: "/contact-us"
}]

function ProfileImageAvatar() {
  const { data, loading, error }= useQuery(gql`{
    me {
      photo {
        url
      }
    }
  }`)

  console.log(data);

  if (!loading && !error && data && data.me && data.me.photo && data.me.photo.url) {
    return <ProfileImage src={data.me.photo.url} alt="Profile Image" />
  }
  return <ProfileImage src={profile} alt="Profile Image" />
}

function Header() {
  const { t } = useTranslation("Header")

  const [menuOpen, setMenuOpen] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      meerkatLogo: file(relativePath: { eq: "Meerkat-Logo329.png" }) {
        childImageSharp {
          fluid(maxHeight: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      profileImage: file(relativePath: { eq: "Group.png" }) {
        childImageSharp {
          fixed(height: 54) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const loggedIn = isLoggedIn()

  return (
    <HeaderContainer>
      <OpenSideBarContainer onClick={() => setMenuOpen(true)}>
        <img src={openMenu} alt="Menu" />
      </OpenSideBarContainer>

      <SideMenu open={menuOpen} setOpen={setMenuOpen} />

      <LogoContainer>
        <LogoLink to="/">
          <Logo src={logo} alt="Meerkat" />
        </LogoLink>
      </LogoContainer>

      <TopMenuContainer>
        <TopMenu>
          {menuOptions.map(o => (
            o.href ? 
            <a href={o.href}>
              <TopMenuItem><Text {...(o.testId ? { "data-testid": o.testId } : {})} bold={o.bold}>{t(`${o.name}`)}</Text></TopMenuItem>
            </a>
            : o.to ? <Link to={o.to}>
              <TopMenuItem><Text {...(o.testId ? { "data-testid": o.testId } : {})} bold={o.bold}>{t(`${o.name}`)}</Text></TopMenuItem>
            </Link> :
              <TopMenuItem><Text {...(o.testId ? { "data-testid": o.testId } : {})} bold={o.bold}>{t(`${o.name}`)}</Text></TopMenuItem>
          ))}
        </TopMenu>
      </TopMenuContainer>

      <Link to={loggedIn ? '/' : '/sign-in'}>
        <ProfileContainer>
          <Row>
            <Col centerVertical>
            {
              loggedIn
                ? <ProfileImageAvatar />
                : <ProfileImage src={profile} alt="Profile Image" />
            }
            </Col>
            <ProfileText>{loggedIn ? t('myProfile') : t('signIn')}</ProfileText>
          </Row>
        </ProfileContainer>
      </Link>
      {/*<LanguageSwitcherContainer>
        <LanguageSwitcher />
      </LanguageSwitcherContainer>*/}
    </HeaderContainer>
  )
}

Header.menuOptions = menuOptions

export default Header
