import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../Header"
import Footer from '../Footer'
import Moku from '../Moku'
import { GlobalStyle } from '../../theme.style'
import { ContentContainer, getCookieBarStyle, getAcceptCookieButtonStyle } from './Layout.style'
import './layout.css'
import CookieConsent from "react-cookie-consent";
import { useTheme } from "emotion-theming"

function Layout({ children, noHeader, noFooter, noMoku }) {
  const theme = useTheme()

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  
  return (
    <>
      <GlobalStyle />
      {!noHeader && <Header siteTitle={data.site.siteMetadata.title} />}
      <ContentContainer>
        <main>{children}</main>
        {!noFooter && <Footer />}
        {false && !noMoku && <Moku />}
      </ContentContainer>
      <CookieConsent 
        style={getCookieBarStyle(theme)}
        buttonStyle={getAcceptCookieButtonStyle(theme)}
      >
        This site uses cookies to provide you with a better user experience. By continuing browsing on this site, you accept our use of cookies detailed in our <a style={{ color: "white", fontWeight: 900 }} href="https://www.meerkat.co.za/privacy-policy">Privacy Policy</a>
      </CookieConsent>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
