import React from 'react'
import styled from '@emotion/styled'
import { device } from '../../theme.style'
import { Link } from 'gatsby'
import { Text } from '../generic'
import Img from 'gatsby-image'

const FooterContainer = styled.footer`
  position: relative;
  background-color: ${props => props.theme.colors.dark};
  display: flex;
  flex-direction: column;
  flex-grow: 1
  align-items: center;
  width: 100%;
  padding-top: 52px;
  padding-bottom: 52px;
  padding-left: 7.8%;
  padding-right: 7.8%;

  ${device.tablet`
    padding-top: 38px;
    padding-bottom: 38px;
  `}
`

const LogoContainer = styled.div`
  margin-top: 42px;
  height: 50px;
  width: 227px;

  ${device.tablet`
    margin-top: 0px;
    margin-bottom: 14px;
    width: 63px;
    height: 14px;
  `}
`

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${device.tablet`
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
  `}
`

const DetailItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;

  ${device.tablet`
    text-align: center;
    align-items: center;
  `}
`

const LegalLink = styled.a`
  padding-right: 60px;
  margin-bottom: 14px;

  ${device.tablet`
    padding-right: 0px;
  `}
`

const LegalLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  
  ${device.tablet`
    flex-direction: column;
    flex-grow: 1;
    align-items: center; 
  `}
`

const HorizontalRule = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.15);
  width: 100%;
  height: 3px;

  ${device.tablet`
    display: none;
  `}
`

const DetailItemHeader = styled(Text)`
  margin-bottom: 14px;
`

DetailItemHeader.defaultProps = {
  bold: true,
  white: true
}

const AddressText = styled(Text)`
  width: 190px;

  ${device.tablet`
    width: auto;
  `}
`

AddressText.defaultProps = {
  medium: true,
  white: true
}

const CopyrightText = styled(Text)`
  position: absolute;
  bottom: 80px;
  right: 7.8%;
  max-width: 46%;
  text-align: right;

  ${device.tablet`
    position: relative;
    right: auto;
    bottom: auto;
    max-width: 100%;
    text-align: center;
  `}
` 

const SocialIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 252px;
  justify-content: space-between;

  ${device.tablet`
    width: 222px;
  `}
`

CopyrightText.defaultProps = {
  small: true,
  bold: true,
  white: true 
}

const SocialIcon = styled.a`
  width: 32px;
  heigth: 32px;

  ${device.tablet`
    width: 25px;
    heigth: 25px;
  `}
`

const SocialImage = styled.img`
  width: 32px;
  height: 32px;
  margin: 0;
`

export {
  FooterContainer,
  LogoContainer,
  DetailsContainer,
  DetailItemContainer,
  DetailItemHeader,
  LegalLinksContainer,
  HorizontalRule,
  CopyrightText,
  AddressText,
  SocialIconsContainer,
  LegalLink,
  SocialIcon,
  SocialImage
}
