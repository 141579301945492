import React from 'react'
import { SideMenuContainer, MenuItemContainer, CloseMenuContainer } from './SideMenu.style'
import { Text, Button, Modal } from '../generic'
import { whiteClose } from '../../images'
import { useTranslation } from '../../hooks'
import { isLoggedIn } from "../../services/auth"
import Header from '../Header'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

function SideMenu({ open, setOpen }){
  const { t } = useTranslation("Header")
  const loggedIn = isLoggedIn()

  if (!open) { return <div /> }

  return <SideMenuContainer>
    <CloseMenuContainer onClick={() => setOpen(false)}>
      <img src={whiteClose} alt="Close" />
    </CloseMenuContainer>
    <MenuItemContainer><Link to={"/"} onClick={() => setOpen(false)}><Text bold white>{t("home")}</Text></Link></MenuItemContainer>
    {Header.menuOptions.map(menuOption => (
      <MenuItemContainer key={menuOption.name}><Link to={menuOption.to} onClick={() => setOpen(false)}><Text bold white>{t(menuOption.name)}</Text></Link></MenuItemContainer>
    ))}
    <MenuItemContainer><Link to={loggedIn ? "/" : "/sign-in"} onClick={() => setOpen(false)}><Text bold white>{loggedIn ? t('myProfile') : t('signIn')}</Text></Link></MenuItemContainer>
  </SideMenuContainer>
}

SideMenu.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func
}

export default SideMenu